import { MDBCard, MDBCardBody } from 'mdbreact';

import { Link } from "gatsby";
import React from 'react';
import styled from 'styled-components';

let gmdv = require(`../../ghc_config`);

const MDBCardComp = styled(MDBCard)`
    h1, h2, h3, h4, h5, h6 {
        font-weight: 300!important;
    }
    h5 {
        font-size: 1.15em;
        font-weight: 400!important;
    }
    box-shadow: none;
    a {
        display:block;
        margin: 10px auto;
        text-align: justify;
    }
    @media only screen and (max-width: 1200px) {
        min-height: 370px;
    }
    @media only screen and (max-width: 960px) {
        min-height: 300px; 
        h5 {
            font-size: 0.83em;           
        }
    }
    @media only screen and (max-width: 760px) {
        min-height: 200px;
    }
`

const Events = (props) => {
    return (
        <MDBCardComp>
            <MDBCardBody>
                {props.ResourcesList.edges.map(({node}, key) => {

                    let date = gmdv.dateProcessed(node.field_happening_s_date);

                    let month = date.toLocaleString('en-us', { month: 'long' });
                    let year = date.getFullYear();
                    let titleResources = `${month} ${date.getDate()}, ${year} ${node.title}`

                    let linkBuilding = ``
                    if (node.field_link_to_the_page) {
                        linkBuilding = (
                        <a href={node.field_link_to_the_page.toLowerCase()} key={key} target='_blank'>
                            <h5>{titleResources}</h5>
                            {(node.body && node.body.summary) && <h6>{node.body.summary}</h6>}
                        </a>)
                    }
                    else {
                        linkBuilding = (
                        <Link to={node.path.alias.toLowerCase()} key={key}>
                            <h5>{titleResources}</h5>
                            {(node.body && node.body.summary) && <h6>{node.body.summary}</h6>}
                        </Link>)
                    }
                    
                    return (linkBuilding)
                })}
            </MDBCardBody>
        </MDBCardComp>
    )
}


export default Events;