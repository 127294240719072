import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../components/menus/corpresourcesbcmenu';
import Layout from '../components/layout';
import React from 'react';
import Resources from '../components/resources/resources';
import SEO from '../components/seo';
import SideNavBar from '../components/menus/resourcesnavbarmenu';
import { graphql } from 'gatsby';

/** This page is a view is not in Drupal  */

const Detail = ({ data }) => {
  const ResourcesList = data.allNodeResourcesCorp
  return (
    <Layout>
      <SEO 
        title={`Resources`} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="leftside">
              <SideNavBar />
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${'/images/banners/SectionsBox/Healthcare_resources.png'})`}}>
                Resources
              </h1>
              <BreadcrumbMenu title="Resources"/>
              <Resources ResourcesList={ResourcesList}/>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
query allResourcesList {
  allNodeResourcesCorp(
    sort: {fields: field_happening_s_date, order: DESC}, 
    filter: {field_show_on_the_resources_list: {eq: true}}) {
    edges{
      node {
        field_happening_s_date
        field_link_to_the_page
        title
        body {
          value
          summary
        }
        path {
          alias
        }
      }
    }
  }
}
`